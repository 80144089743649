import React from "react";
// scss
import styles from "./rating.module.scss";
// image
import reviewsio from "../../public/reviews-io.png";
import sitejabber from "../../public/sitejabber.webp";
import cheapestEssay from "../../public/Cheapestessay.webp";
import Image from 'next/image';
import { Col, Row } from "react-bootstrap";

function HeaderRating() {
  return (
    <>
      <Row>
        <Col className={`${styles.rating} review-io`}>
          <div className={styles.left}>
            <Image
              src={reviewsio}
              alt="reviews.io"
              width={25}
              height={25}
              title="Reviews.io"
            />
          </div>
          <div className={styles.right}>
            <div className={styles.name}>Reviews.io</div>
            <div className={styles.rate}>
              <span className={styles.span}>4.8</span>
              <Image
                src="/stars.svg"
                alt="Stars"
                title="Stars"
                width={80}
                height={20}
              />
            </div>
          </div>
        </Col>
        <Col className={styles.rating}>
          <div className={styles.left}>
            <Image
              src={sitejabber}
              alt="sitejabber"
              width={25}
              height={25}
              title="Sitejabbar"
            />
          </div>
          <div className={styles.right}>
            <div className={styles.name}>Sitejabber</div>
            <div className={styles.rate}>
              <span className={styles.span}>4.7</span>
              <Image
                src="/stars.svg"
                alt="Stars"
                title="Stars"
                width={80}
                height={20}
              />
            </div>
          </div>
        </Col>
        <Col className={styles.rating}>
          <div className={styles.left}>
            <Image
              src={cheapestEssay}
              alt="cheapestessay"
              width={25}
              height={25}
              title="Cheapestessay"
            />

          </div>
          <div className={styles.right}>
            <div className={styles.name}>Cheapestessay</div>
            <div className={styles.rate}>
              <span className={styles.span}>4.9</span>
              <Image
                src="/stars.svg"
                alt="Stars"
                title="Stars"
                width={80}
                height={20}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default HeaderRating;
